html, body {
    font-family: var(--global-font-family);
    a {
        color: var(--global-color-primary);
        &:hover, &:focus {
            color: var(--global-color-secondary); // careful of <a class="uk-button> buttons
        }
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: var(--global-title-font);
        font-weight: 700;
    }
}
.h1, h1 {
    font-size: 2em;
}
.h2, h2 {
    font-size: 1.5em;
}
.h3, h3 {
    font-size: 1.17em;
}
.h4, h4 {
    font-size: 1em;
}
.h5, h5 {
    font-size: .83em;
}
.h6, h6 {
    font-size: .67em;
}

.cover-title {
    font-size: 4em;
    font-weight: 800;
    line-height: e;
    text-shadow: 5px 5px 10px lightgray;
}

.text-color-secondary {
    color: var(--global-color-secondary) !important;
}


p {
    font-weight: 500;
    font-size: 1.1em;
    text-align: left;
}

blockquote {
    border-left: 10px solid var(--global-color-secondary);
    padding: 10px 30px;
}

em {
    color: #666 // default
}